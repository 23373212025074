<template>
  <div class="root">
    <teleport to="body">
      <div class="modal">
        <div>
          <img
            :src="require('@/assets/' + pictureSrc)"
            alt="Oversiktsbilde av området"
          />
          <button class="btn btn-danger" @click="$emit('abort')">Lukk</button>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PictureModal",
  props: ["pictureSrc"],
  emits: ["abort"],
  setup() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
});
</script>
<style scoped>
.root {
  position: relative;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  height: calc(var("--vh", 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal > div {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 80%;
}
.btn {
  margin: 10px 10px 0px;
}
img {
  display: block;
  width: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .modal > div {
    padding: 10px;
    padding-top: 25px;
  }
}
</style>
