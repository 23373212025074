
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  setup() {
    const spinnerText = "Laster..";

    return {
      spinnerText,
    };
  },
});
