<template>
  <div class="spinner-container">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
    <p role="status">{{ spinnerText }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  setup() {
    const spinnerText = "Laster..";

    return {
      spinnerText,
    };
  },
});
</script>

<style scoped>
.spinner-background {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  position: absolute;
  align-items: center;
  top: 5;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 3%;
  margin-bottom: 1%;
  vertical-align: middle;
}
p {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
