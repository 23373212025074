
import { defineComponent, onMounted, ref, watch } from "vue";
import Datepicker from "vue3-datepicker";
import { useStore } from "vuex";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "PickDate",
  components: { Datepicker, BaseCard },
  props: ["title", "limit", "adminCss"],
  setup() {
    const store = useStore();
    const picked = ref(new Date());

    watch([picked], () => {
      const date = picked.value.getDate();
      const month = picked.value.getMonth() + 1;
      const year = picked.value.getFullYear();
      const fullDate = date + ":" + month + ":" + year;

      store.commit({
        type: "setPickedDate",
        pickedDate: fullDate,
      });
    });

    onMounted(() => {
      const date = picked.value.getDate();
      const month = picked.value.getMonth() + 1;
      const year = picked.value.getFullYear();
      const fullDate = date + ":" + month + ":" + year;

      store.commit({
        type: "setPickedDate",
        pickedDate: fullDate,
      });
    });

    return {
      picked,
    };
  },
});
