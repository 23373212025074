
import {
  defineComponent,
  computed,
  ref,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import firebase from "firebase";
import Spinner from "@/components/UI/Spinner.vue";

export default defineComponent({
  name: "Multiselect",
  components: { Spinner },
  props: ["isBookingPage"],
  setup(props) {
    const database = firebase.database().ref("all_bookings");
    const store = useStore();
    const dateLimit = null;
    const isLoading = ref(false);
    const useChoiceBtnText = ref("Fortsett bookingen");

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });
    const pickedDate = computed(() => {
      return store.getters.getPickedDate;
    });
    const fromTimes = computed(() => {
      const fromTimes = [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
      ];

      const now = new Date();
      const month = now.getMonth() + 1;
      const dateNow =
        now.getDate().toString() +
        ":" +
        month.toString() +
        ":" +
        now.getFullYear().toString();

      if (dateNow === pickedDate.value) {
        const timeNow =
          ("0" + now.getHours().toString()).slice(-2) +
          ":" +
          ("0" + now.getMinutes().toString()).slice(-2);

        const times = fromTimes.filter((a) => {
          return a >= timeNow;
        });
        return times;
      }

      return fromTimes;
    });

    const toTimes = [
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
    ];

    const chosenTimes = ref([""]);
    const formData = reactive({
      field: "",
      fromTime: "",
      toTime: "",
    });
    const timeError = ref("");
    const validTimes = ref(false);
    const validFromTime = ref(false);
    const validToTime = ref(false);
    const validField = ref(false);

    watch(
      () => pickedDate.value,
      () => {
        formData.fromTime = "";
        formData.toTime = "";
      }
    );

    watch([formData], () => {
      if (formData.field === "") {
        validField.value = false;
      } else {
        validField.value = true;
      }

      if (formData.fromTime.length > 4) {
        validFromTime.value = true;
      } else {
        validFromTime.value = false;
      }

      if (formData.toTime.length > 4) {
        validToTime.value = true;
      } else {
        validToTime.value = false;
      }

      if (validField.value && validFromTime.value && validToTime.value) {
        checkTime();
      }
    });

    const checkTime = async () => {
      isLoading.value = true;
      let errorOccured = false;
      if (formData.field && formData.fromTime && formData.toTime) {
        // validerer at input er ok
        if (formData.fromTime === formData.toTime) {
          timeError.value = "Til- og fra-tiden kan ikke være lik";
          validTimes.value = false;
          errorOccured = true;
        } else if (formData.fromTime > formData.toTime) {
          timeError.value = "Fra-tiden kan ikke være etter til-tiden";
          validTimes.value = false;
          errorOccured = true;
        } else {
          timeError.value = "";

          // lager array med valgte tider
          chosenTimes.value.length = 0;
          fromTimes.value.forEach((time) => {
            if (time >= formData.fromTime && time < formData.toTime) {
              chosenTimes.value.push(time);
            }
          });

          // sjekker om alle valgte tider er ledige
          await database
            .child(pickedDate.value)
            .child(formData.field)
            .get()
            .then((snapshot) => {
              if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                  chosenTimes.value.forEach((chosenTime) => {
                    if (
                      chosenTime === childSnapshot.val().originalTime &&
                      childSnapshot.val().status !== "Ledig"
                    ) {
                      errorOccured = true;
                      timeError.value =
                        formData.field +
                        " er dessverre ikke ledig dette tidspunktet.";
                    }
                  });
                });
              }
            });

          if (errorOccured) {
            validTimes.value = false;
          } else {
            // Sjekker at tidspunktet ikke er valgt fra før
            for (let i = 0; i < chosenTimes.value.length; i++) {
              for (let j = 0; j < chosenBookings.value.length; j++) {
                if (
                  pickedDate.value === chosenBookings.value[j].date &&
                  formData.field === chosenBookings.value[j].field
                ) {
                  let bookingTimes = [];
                  if (chosenBookings.value[j].time.includes("-")) {
                    const timesplit = chosenBookings.value[j].time.split(" - ");
                    const fromTime = timesplit[0];
                    const toTime = timesplit[1];

                    // lager array med valgte tider
                    fromTimes.value.forEach((time) => {
                      if (time >= fromTime && time < toTime) {
                        bookingTimes.push(time);
                      }
                    });
                  } else {
                    bookingTimes.push(chosenBookings.value[j].time);
                  }

                  bookingTimes.forEach((bookingtime) => {
                    if (chosenTimes.value[i] === bookingtime) {
                      errorOccured = true;
                      timeError.value =
                        "Hele eller deler av tidspunktet er allerede valgt.";
                    }
                  });
                }
              }
            }
            if (!errorOccured) {
              validTimes.value = true;
            } else {
              validTimes.value = false;
            }
          }
        }
      }
      isLoading.value = false;
    };

    const useChoice = () => {
      const time = formData.fromTime + " - " + formData.toTime;
      if (props.isBookingPage) {
        store.commit({
          type: "addChosenBooking",
          date: pickedDate.value,
          time: time,
          field: formData.field,
        });
        formData.field = "";
        formData.fromTime = "";
        formData.toTime = "";
        validTimes.value = false;
      } else {
        store.commit({
          type: "newBookingToShow",
          date: pickedDate.value,
          time: time,
          field: formData.field,
          status: "Ledig",
          name: "",
          email: "",
          tlf: "",
          info: "",
        });
        useChoiceBtnText.value = "Oppdater valgene";
        window.scroll({ top: 0, behavior: "smooth" });
      }
    };

    onMounted(() => {
      if (props.isBookingPage) {
        useChoiceBtnText.value = "Legg til";
      }
    });

    return {
      useChoiceBtnText,
      dateLimit,
      isLoading,
      pickedDate,
      formData,
      timeError,
      validField,
      validTimes,
      validFromTime,
      validToTime,
      fromTimes,
      toTimes,
      checkTime,
      useChoice,
    };
  },
});
