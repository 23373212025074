<template>
  <base-card :lessPadding="true">
    <picture-modal
      v-if="modalinfo.isActive"
      :pictureSrc="modalinfo.pictureSrc"
      @abort="modalinfo.isActive = false"
    />
    <table>
      <tbody>
        <tr>
          <td>
            <h2 class="booking-table-header">{{ title }}</h2>
          </td>
          <td v-if="!isBookingPage" @click="showDropdown = !showDropdown">
            <button class="remove-booking-button-temp menu-icon">
              <hamburger-button theme="outline" size="40" fill="#2c3e50" />
            </button>
            <div v-if="showDropdown" class="submenu">
              <ul>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="showToFromChooser = !showToFromChooser"
                  >
                    {{ chooseModeText }}
                  </button>
                </li>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="bookingShortcut(0)"
                  >
                    Hele stadion
                  </button>
                </li>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="bookingShortcut(1)"
                  >
                    Bane 1
                  </button>
                </li>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="bookingShortcut(2)"
                  >
                    Bane 2
                  </button>
                </li>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="bookingShortcut(3)"
                  >
                    Bane 3
                  </button>
                </li>
                <li>
                  <button
                    class="remove-booking-button-temp"
                    @click="bookingShortcut(4)"
                  >
                    Klubbhuset
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <spinner class="spinner" v-if="isLoading" />
    <template v-if="!isLoading && showToFromChooser">
      <multiselect :isBookingPage="false" />
    </template>
    <template v-if="!isLoading && !showToFromChooser">
      <div v-if="isNotWorking">
        <p>Wups, noe har gått galt under henting av dataene.</p>
        <p>Refresh siden og ta kontakt dersom problemet vedvarer.</p>
      </div>
      <div v-else>
        <div v-if="isBookingPage" class="help-text">
          <p>
            Her kan du se hvilke tidspunkter som er ledige, reserverte og
            opptatt.
          </p>
          <table>
            <tr>
              <td class="dummy available">Ledig</td>
              <td class="dummy onHold">Reservert</td>
              <td class="dummy taken">Opptatt</td>
            </tr>
          </table>
          <button class="btn btn-link" @click="modalinfo.isActive = true">
            Trykk her for å se oversikt over området
          </button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Bane 1</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in bane1Bookings" :key="d.originalTime">
              <td
                v-if="!isBookingPage"
                @click="addBooking(d.originalTime, 'Bane 1')"
                class="pointer-cursor"
                :class="getTableCellColor(d.status)"
              >
                {{ d.originalTime }}
              </td>
              <td v-else :class="getTableCellColor(d.status)">
                {{ d.originalTime }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th>Bane 2</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in bane2Bookings" :key="d.originalTime">
              <td
                v-if="!isBookingPage"
                @click="addBooking(d.originalTime, 'Bane 2')"
                class="pointer-cursor"
                :class="getTableCellColor(d.status)"
              >
                {{ d.originalTime }}
              </td>
              <td v-else :class="getTableCellColor(d.status)">
                {{ d.originalTime }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th>Bane 3</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in bane3Bookings" :key="d.originalTime">
              <td
                v-if="!isBookingPage"
                @click="addBooking(d.originalTime, 'Bane 3')"
                class="pointer-cursor"
                :class="getTableCellColor(d.status)"
              >
                {{ d.originalTime }}
              </td>
              <td v-else :class="getTableCellColor(d.status)">
                {{ d.originalTime }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th>Klubbhus</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in klubbhusBookings" :key="d.originalTime">
              <td
                v-if="!isBookingPage"
                @click="addBooking(d.originalTime, 'Klubbhus')"
                class="pointer-cursor"
                :class="getTableCellColor(d.status)"
              >
                {{ d.originalTime }}
              </td>
              <td v-else :class="getTableCellColor(d.status)">
                {{ d.originalTime }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </base-card>
</template>

<script lang="ts">
// TODO: Se på tabellen på bookingsiden iforhold til mobil-format
import { computed, defineComponent, ref, watch, onMounted } from "vue";
import firebase from "firebase";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import Spinner from "../UI/Spinner.vue";
import PictureModal from "../common/PictureModal.vue";
import { HamburgerButton } from "@icon-park/vue-next";
import BaseCard from "@/components/common/BaseCard.vue";
import Multiselect from "./Multiselect.vue";
import BookingViewModel from "@/models/bookingViewModel";

export default defineComponent({
  name: "SeeBookings",
  components: { Spinner, PictureModal, HamburgerButton, BaseCard, Multiselect },
  props: ["title", "isBookingPage"],
  setup() {
    const store = useStore();
    const toast = useToast();
    const database = firebase.database().ref("all_bookings");
    const showDropdown = ref(false);
    const isLoading = ref(false);
    const isNotWorking = ref(false);
    const showToFromChooser = ref(false);
    let bane1Bookings = ref([{ originalTime: "", status: "" }]);
    let bane2Bookings = ref([{ originalTime: "", status: "" }]);
    let bane3Bookings = ref([{ originalTime: "", status: "" }]);
    let klubbhusBookings = ref([{ originalTime: "", status: "" }]);

    const chooseModeText = computed(() => {
      if (showToFromChooser.value) {
        return "Velg i tabell";
      } else {
        return "Velg manuelt";
      }
    });

    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ];

    const modalinfo = ref({
      pictureSrc: "Oversiktsbilde.png",
      isActive: false,
    });

    watch([modalinfo.value], () => {
      if (modalinfo.value.isActive) {
        store.commit({
          type: "updateIsModalOpen",
          value: true,
        });
      } else {
        store.commit({
          type: "updateIsModalOpen",
          value: false,
        });
      }
    });

    const pickedDate = computed(() => {
      return store.getters.getPickedDate;
    });

    watch([pickedDate], () => {
      refreshTable();
    });

    const refreshTable = async () => {
      let dateExist = true;
      let errorCatched = false;
      isLoading.value = true;
      await database
        .child(pickedDate.value)
        .child("Bane 1")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            bane1Bookings.value.length = 0;
            snapshot.forEach(function (childSnapshot) {
              bane1Bookings.value.push(childSnapshot.val());
            });
          } else {
            dateExist = false;
          }
        })
        .catch(() => {
          errorCatched = true;
          // console.error(
          //   "Feil i henting av bane1bookings for " + pickedDate.value + ".",
          //   error
          // );
        });

      await database
        .child(pickedDate.value)
        .child("Bane 2")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            bane2Bookings.value.length = 0;
            snapshot.forEach(function (childSnapshot) {
              bane2Bookings.value.push(childSnapshot.val());
            });
          } else {
            dateExist = false;
          }
        })
        .catch(() => {
          errorCatched = true;
          // console.error(
          //   "Feil i henting av bane2bookings for " + pickedDate.value + ".",
          //   error
          // );
        });

      await database
        .child(pickedDate.value)
        .child("Bane 3")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            bane3Bookings.value.length = 0;
            snapshot.forEach(function (childSnapshot) {
              bane3Bookings.value.push(childSnapshot.val());
            });
          } else {
            dateExist = false;
          }
        })
        .catch(() => {
          errorCatched = true;
          // console.error(
          //   "Feil i henting av bane3bookings for " + pickedDate.value + ".",
          //   error
          // );
        });

      await database
        .child(pickedDate.value)
        .child("Klubbhus")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            klubbhusBookings.value.length = 0;
            snapshot.forEach(function (childSnapshot) {
              klubbhusBookings.value.push(childSnapshot.val());
            });
          } else {
            dateExist = false;
          }
        })
        .catch(() => {
          errorCatched = true;
          // console.error(
          //   "Feil i henting av klubbhusbookings for " + pickedDate.value + ".",
          //   error
          // );
        });

      if (errorCatched) {
        isNotWorking.value = true;
        isLoading.value = false;
      } else if (
        dateExist &&
        bane1Bookings.value.length === 26 &&
        bane2Bookings.value.length === 26 &&
        bane3Bookings.value.length === 26 &&
        klubbhusBookings.value.length === 26
      ) {
        isLoading.value = false;
      } else {
        initDateInDatabase(pickedDate.value);
      }
    };

    const initDateInDatabase = async (date: string) => {
      let errorCatched = false;

      for (let i = 0; i < times.length; i++) {
        await database
          .child(date)
          .child("Bane 1")
          .child(times[i])
          .set({
            originalTime: times[i],
            bookingTime: times[i],
            status: "Ledig",
            name: "",
            email: "",
            tlf: "",
            otherInfo: "",
          })
          .then()
          .catch(() => {
            errorCatched = true;
          });
        await database
          .child(date)
          .child("Bane 2")
          .child(times[i])
          .set({
            originalTime: times[i],
            bookingTime: times[i],
            status: "Ledig",
            name: "",
            email: "",
            tlf: "",
            otherInfo: "",
          })
          .then()
          .catch(() => {
            errorCatched = true;
          });
        await database
          .child(date)
          .child("Bane 3")
          .child(times[i])
          .set({
            originalTime: times[i],
            bookingTime: times[i],
            status: "Ledig",
            name: "",
            email: "",
            tlf: "",
            otherInfo: "",
          })
          .then()
          .catch(() => {
            errorCatched = true;
          });
        await database
          .child(date)
          .child("Klubbhus")
          .child(times[i])
          .set({
            originalTime: times[i],
            bookingTime: times[i],
            status: "Ledig",
            name: "",
            email: "",
            tlf: "",
            otherInfo: "",
          })
          .then()
          .catch(() => {
            errorCatched = true;
          });
      }
      if (errorCatched) {
        isNotWorking.value = true;
        isLoading.value = false;
      } else {
        refreshTable();
      }
    };

    const getTableCellColor = (value: string) => {
      return {
        available: value === "Ledig",
        onHold: value === "Reservert",
        taken: value === "Opptatt",
      };
    };

    const addBooking = async (time: string, field: string) => {
      let chosenBooking = {} as BookingViewModel;
      let allIsGood = true;
      await database
        .child(pickedDate.value)
        .child(field)
        .child(time)
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            chosenBooking = snapshot.val();
          } else {
            allIsGood = false;
          }
        })
        .catch(() => {
          // console.error("Feil under valg av tidspunkt.", error);
          allIsGood = false;
        });

      if (allIsGood) {
        store.commit({
          type: "newBookingToShow",
          date: pickedDate.value,
          time: chosenBooking.bookingTime,
          field: field,
          status: chosenBooking.status,
          name: chosenBooking.name,
          email: chosenBooking.email,
          tlf: chosenBooking.tlf,
          info: chosenBooking.otherInfo,
        });
        window.scroll({ top: 0, behavior: "smooth" });
      } else {
        toast.error(
          "Valg av tidspunkt feilet. Prøv igjen og ta kontakt om feilen vedvarer."
        );
      }
    };

    const bookingShortcut = async (option: number) => {
      let field = "";
      switch (option) {
        case 0: {
          field = "HELE OMRÅDET";
          break;
        }
        case 1: {
          field = "Bane 1";
          break;
        }
        case 2: {
          field = "Bane 2";
          break;
        }
        case 3: {
          field = "Bane 3";
          break;
        }
        case 4: {
          field = "Klubbhus";
          break;
        }
      }

      // Sjekker at alle tidspunkter er ledige eller identisk booket
      let isAvailable = true;
      let error = false;
      let prevBooking = {
        status: "",
        name: "",
        email: "",
        tlf: "",
        otherInfo: "",
      };
      if (field === "HELE OMRÅDET") {
        const fields = ["Bane 1", "Bane 2", "Bane 3", "Klubbhus"];
        for (let i = 0; i < fields.length; i++) {
          await database
            .child(pickedDate.value)
            .child(fields[i])
            .get()
            .then((snapshot) => {
              if (snapshot.exists()) {
                snapshot.forEach(function (childSnapshot) {
                  if (
                    fields[i] === "Bane 1" &&
                    childSnapshot.val().originalTime === "08:00"
                  ) {
                    prevBooking = childSnapshot.val();
                  } else {
                    if (
                      childSnapshot.val().status !== prevBooking.status ||
                      childSnapshot.val().name !== prevBooking.name ||
                      childSnapshot.val().email !== prevBooking.email ||
                      childSnapshot.val().tlf !== prevBooking.tlf ||
                      childSnapshot.val().otherInfo !== prevBooking.otherInfo
                    ) {
                      isAvailable = false;
                    }
                    prevBooking = childSnapshot.val();
                  }
                });
              } else {
                error = true;
              }
            })
            .catch(() => {
              error = true;
              // console.error(
              //   "Feil under sjekk av status " +
              //     field +
              //     " den " +
              //     pickedDate.value,
              //   error
              // );
            });
        }
      } else {
        await database
          .child(pickedDate.value)
          .child(field)
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              snapshot.forEach(function (childSnapshot) {
                if (childSnapshot.val().originalTime === "08:00") {
                  prevBooking = childSnapshot.val();
                } else {
                  if (
                    childSnapshot.val().status !== prevBooking.status ||
                    childSnapshot.val().name !== prevBooking.name ||
                    childSnapshot.val().email !== prevBooking.email ||
                    childSnapshot.val().tlf !== prevBooking.tlf ||
                    childSnapshot.val().otherInfo !== prevBooking.otherInfo
                  ) {
                    isAvailable = false;
                  }
                  prevBooking = childSnapshot.val();
                }
              });
            } else {
              error = true;
            }
          })
          .catch(() => {
            error = true;
            // console.error(
            //   "Feil under sjekk av status " +
            //     field +
            //     " den " +
            //     pickedDate.value,
            //   error
            // );
          });
      }

      // Hvis tilgjengelig for batch-behandling og det ikke har oppstått noen feil, sender bookingen til store.
      if (!isAvailable) {
        toast.error(
          field +
            " har ikke alle bookinger samme status, og kan derfor ikke behandles samtidig."
        );
      } else if (error) {
        toast.error(
          "Feil under sjekk av tilgjengelighet. Prøv igjen og ta kontakt om feilen vedvarer"
        );
      } else {
        store.commit({
          type: "newBookingToShow",
          date: pickedDate.value,
          time: "HELE DAGEN",
          field: field,
          status: prevBooking.status,
          name: prevBooking.name,
          email: prevBooking.email,
          tlf: prevBooking.tlf,
          info: prevBooking.otherInfo,
        });
        window.scroll({ top: 0, behavior: "smooth" });
      }
    };

    onMounted(() => {
      refreshTable();
    });

    return {
      showToFromChooser,
      chooseModeText,
      isLoading,
      isNotWorking,
      bane1Bookings,
      bane2Bookings,
      bane3Bookings,
      klubbhusBookings,
      modalinfo,
      showDropdown,
      getTableCellColor,
      addBooking,
      bookingShortcut,
    };
  },
});
</script>

<style scoped>
.booking-table-header {
  display: inline;
  margin-bottom: 3%;
}
.pointer-cursor {
  cursor: pointer;
}
table {
  display: inline;
}
.btn-link {
  margin-top: 1rem;
}
.dummy {
  padding: 0.5rem;
  width: 85px;
  cursor: auto;
}
th {
  width: 85px;
  cursor: auto;
}
.help-text {
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 350px;
}
.available {
  background-color: rgba(0, 128, 0, 0.5) !important;
}
.onHold {
  background-color: rgba(255, 166, 0, 0.5) !important;
}
.taken {
  background-color: rgba(255, 0, 0, 0.5) !important;
}
.spinner {
  margin-top: 3rem;
}
.remove-booking-button-temp {
  position: relative;
}
.menu-icon {
  margin-left: 1rem;
}
.submenu {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5%;
  width: 140px;
}
ul {
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  text-align: left;
}
li {
  padding: 6px 0;
  border-radius: 5%;
}
li:hover {
  background-color: rgb(216, 216, 216);
}
</style>
