<template>
  <base-card>
    <div class="datepicker">
      <h2 class="datepicker-heading" :class="adminCss">{{ title }}</h2>
      <datepicker
        inputFormat="dd.MM.yyyy"
        v-model="picked"
        :inline="true"
        :lower-limit="limit"
      />
    </div>
  </base-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import Datepicker from "vue3-datepicker";
import { useStore } from "vuex";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "PickDate",
  components: { Datepicker, BaseCard },
  props: ["title", "limit", "adminCss"],
  setup() {
    const store = useStore();
    const picked = ref(new Date());

    watch([picked], () => {
      const date = picked.value.getDate();
      const month = picked.value.getMonth() + 1;
      const year = picked.value.getFullYear();
      const fullDate = date + ":" + month + ":" + year;

      store.commit({
        type: "setPickedDate",
        pickedDate: fullDate,
      });
    });

    onMounted(() => {
      const date = picked.value.getDate();
      const month = picked.value.getMonth() + 1;
      const year = picked.value.getFullYear();
      const fullDate = date + ":" + month + ":" + year;

      store.commit({
        type: "setPickedDate",
        pickedDate: fullDate,
      });
    });

    return {
      picked,
    };
  },
});
</script>
<style>
.datepicker-heading {
  margin-bottom: 13%;
}
.datepicker {
  position: relative;
  display: inline-block;
  padding-bottom: 17rem;
}
.v3dp__datepicker {
  position: absolute;
  left: -15px;
}
.v3dp__input_wrapper {
  display: none;
}
.v3dp__popout {
  display: block !important;
}
.datepicker-heading {
  width: 250px;
}
</style>
