<template>
  <div class="multiselect-form">
    <div v-if="isBookingPage" class="info-text">
      <p>Obs. hvis du trenger agility-utstyr må du velge bane 2.</p>
    </div>
    <form>
      <div class="form-group">
        <label for="field" class="text-label">Område *</label>
        <select
          v-model="formData.field"
          id="field"
          class="form-select"
          :class="validField ? 'input-right' : 'input-wrong'"
        >
          <option disabled value="">Velg område</option>
          <option value="Bane 1">Bane 1</option>
          <option value="Bane 2">Bane 2 (Agility)</option>
          <option value="Bane 3">Bane 3</option>
          <option value="Klubbhus">Klubbhus</option>
        </select>
        <p :class="validField ? 'hidden' : 'input-error'">
          * Feltet må fylles ut
        </p>
      </div>
      <div class="form-group">
        <span
          v-if="isBookingPage && formData.field === 'Klubbhus'"
          class="margin0auto"
        >
          Klubbhuset kan leies til kl. 02 selvom du bare kan booke til kl. 21
          her.
        </span>
      </div>

      <div class="form-group time-input" id="from-time-input">
        <label for="fromTime" class="text-label">Fra kl. *</label>
        <select
          name="fromTime"
          id="fromTime"
          class="form-control margin0auto"
          :class="validFromTime ? 'input-right' : 'input-wrong'"
          v-model="formData.fromTime"
          @change="checkTime"
        >
          <option disabled value="">Velg tidspunkt..</option>
          <option v-for="t in fromTimes" :key="t" :value="t">{{ t }}</option>
        </select>
        <p :class="validFromTime ? 'hidden' : 'input-error'">
          * Feltet må fylles ut
        </p>
      </div>
      <div class="form-group time-input" id="to-time-input">
        <label for="toTime" class="text-label">Til kl. *</label>
        <select
          name="toTime"
          id="toTime"
          class="form-control margin0auto"
          :class="validToTime ? 'input-right' : 'input-wrong'"
          v-model="formData.toTime"
          @change="checkTime"
        >
          <option disabled value="">Velg tidspunkt..</option>
          <option v-for="t in toTimes" :key="t" :value="t">{{ t }}</option>
        </select>
        <p :class="validToTime ? 'hidden' : 'input-error'">
          * Feltet må fylles ut
        </p>
      </div>
      <p v-if="timeError != ''" class="time-error">{{ timeError }}</p>
    </form>
    <spinner v-if="isLoading" class="align-center form-group" />
    <div class="align-center" v-if="validTimes">
      <button class="btn btn-success" @click="useChoice">
        {{ useChoiceBtnText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  watch,
  reactive,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import firebase from "firebase";
import Spinner from "@/components/UI/Spinner.vue";

export default defineComponent({
  name: "Multiselect",
  components: { Spinner },
  props: ["isBookingPage"],
  setup(props) {
    const database = firebase.database().ref("all_bookings");
    const store = useStore();
    const dateLimit = null;
    const isLoading = ref(false);
    const useChoiceBtnText = ref("Fortsett bookingen");

    const chosenBookings = computed(() => {
      return store.getters.getChosenBookings;
    });
    const pickedDate = computed(() => {
      return store.getters.getPickedDate;
    });
    const fromTimes = computed(() => {
      const fromTimes = [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
      ];

      const now = new Date();
      const month = now.getMonth() + 1;
      const dateNow =
        now.getDate().toString() +
        ":" +
        month.toString() +
        ":" +
        now.getFullYear().toString();

      if (dateNow === pickedDate.value) {
        const timeNow =
          ("0" + now.getHours().toString()).slice(-2) +
          ":" +
          ("0" + now.getMinutes().toString()).slice(-2);

        const times = fromTimes.filter((a) => {
          return a >= timeNow;
        });
        return times;
      }

      return fromTimes;
    });

    const toTimes = [
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
    ];

    const chosenTimes = ref([""]);
    const formData = reactive({
      field: "",
      fromTime: "",
      toTime: "",
    });
    const timeError = ref("");
    const validTimes = ref(false);
    const validFromTime = ref(false);
    const validToTime = ref(false);
    const validField = ref(false);

    watch(
      () => pickedDate.value,
      () => {
        formData.fromTime = "";
        formData.toTime = "";
      }
    );

    watch([formData], () => {
      if (formData.field === "") {
        validField.value = false;
      } else {
        validField.value = true;
      }

      if (formData.fromTime.length > 4) {
        validFromTime.value = true;
      } else {
        validFromTime.value = false;
      }

      if (formData.toTime.length > 4) {
        validToTime.value = true;
      } else {
        validToTime.value = false;
      }

      if (validField.value && validFromTime.value && validToTime.value) {
        checkTime();
      }
    });

    const checkTime = async () => {
      isLoading.value = true;
      let errorOccured = false;
      if (formData.field && formData.fromTime && formData.toTime) {
        // validerer at input er ok
        if (formData.fromTime === formData.toTime) {
          timeError.value = "Til- og fra-tiden kan ikke være lik";
          validTimes.value = false;
          errorOccured = true;
        } else if (formData.fromTime > formData.toTime) {
          timeError.value = "Fra-tiden kan ikke være etter til-tiden";
          validTimes.value = false;
          errorOccured = true;
        } else {
          timeError.value = "";

          // lager array med valgte tider
          chosenTimes.value.length = 0;
          fromTimes.value.forEach((time) => {
            if (time >= formData.fromTime && time < formData.toTime) {
              chosenTimes.value.push(time);
            }
          });

          // sjekker om alle valgte tider er ledige
          await database
            .child(pickedDate.value)
            .child(formData.field)
            .get()
            .then((snapshot) => {
              if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                  chosenTimes.value.forEach((chosenTime) => {
                    if (
                      chosenTime === childSnapshot.val().originalTime &&
                      childSnapshot.val().status !== "Ledig"
                    ) {
                      errorOccured = true;
                      timeError.value =
                        formData.field +
                        " er dessverre ikke ledig dette tidspunktet.";
                    }
                  });
                });
              }
            });

          if (errorOccured) {
            validTimes.value = false;
          } else {
            // Sjekker at tidspunktet ikke er valgt fra før
            for (let i = 0; i < chosenTimes.value.length; i++) {
              for (let j = 0; j < chosenBookings.value.length; j++) {
                if (
                  pickedDate.value === chosenBookings.value[j].date &&
                  formData.field === chosenBookings.value[j].field
                ) {
                  let bookingTimes = [];
                  if (chosenBookings.value[j].time.includes("-")) {
                    const timesplit = chosenBookings.value[j].time.split(" - ");
                    const fromTime = timesplit[0];
                    const toTime = timesplit[1];

                    // lager array med valgte tider
                    fromTimes.value.forEach((time) => {
                      if (time >= fromTime && time < toTime) {
                        bookingTimes.push(time);
                      }
                    });
                  } else {
                    bookingTimes.push(chosenBookings.value[j].time);
                  }

                  bookingTimes.forEach((bookingtime) => {
                    if (chosenTimes.value[i] === bookingtime) {
                      errorOccured = true;
                      timeError.value =
                        "Hele eller deler av tidspunktet er allerede valgt.";
                    }
                  });
                }
              }
            }
            if (!errorOccured) {
              validTimes.value = true;
            } else {
              validTimes.value = false;
            }
          }
        }
      }
      isLoading.value = false;
    };

    const useChoice = () => {
      const time = formData.fromTime + " - " + formData.toTime;
      if (props.isBookingPage) {
        store.commit({
          type: "addChosenBooking",
          date: pickedDate.value,
          time: time,
          field: formData.field,
        });
        formData.field = "";
        formData.fromTime = "";
        formData.toTime = "";
        validTimes.value = false;
      } else {
        store.commit({
          type: "newBookingToShow",
          date: pickedDate.value,
          time: time,
          field: formData.field,
          status: "Ledig",
          name: "",
          email: "",
          tlf: "",
          info: "",
        });
        useChoiceBtnText.value = "Oppdater valgene";
        window.scroll({ top: 0, behavior: "smooth" });
      }
    };

    onMounted(() => {
      if (props.isBookingPage) {
        useChoiceBtnText.value = "Legg til";
      }
    });

    return {
      useChoiceBtnText,
      dateLimit,
      isLoading,
      pickedDate,
      formData,
      timeError,
      validField,
      validTimes,
      validFromTime,
      validToTime,
      fromTimes,
      toTimes,
      checkTime,
      useChoice,
    };
  },
});
</script>
<style scoped>
.info-text {
  margin: 0 auto;
  text-align: center;
}
.multiselect-form {
  text-align: left;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .time-input {
    display: inline-block;
    width: 45%;
  }
  #to-time-input {
    margin-left: 5%;
  }
  #from-time-input {
    margin-right: 5%;
  }
}
@media screen and (max-width: 768px) {
  .multiselect-form {
    width: 70%;
  }
}
.form-group {
  margin-top: 6%;
}
.radio-option {
  margin-bottom: 1%;
}
.radio-label {
  padding-left: 5px;
}
.text-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.margin0auto {
  margin: 0 auto;
}
.input-wrong {
  border: 1px solid red;
}
.input-right {
  border: 1px solid green;
}
.input-error {
  visibility: visible;
  font-size: 0.8rem;
  color: red;
  margin-bottom: 0;
}
.time-error {
  color: red;
  margin-bottom: 0;
  text-align: center;
}
.hidden {
  visibility: hidden;
  font-size: 0.8rem;
  margin-bottom: 0;
}
.align-center {
  text-align: center;
}
.btn {
  margin: 0 auto;
}
</style>
